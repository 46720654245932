import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';

import Layout from 'components/UI/Layout';
import ScreenTitle from 'components/Shared/ScreenTitle';
import { mapGetMarkers } from 'api/map';
import { useLanguage } from 'languages/languageContext';
import { ChevronLeft, FilterListOutlined, InfoOutlined } from '@material-ui/icons';
import { getConfig } from 'config/config';

import MapMarker from './MapMarker';
import Button from 'components/UI/Button';
import { ButtonBase, Grid, makeStyles } from '@material-ui/core';
import AlertModal from 'components/Shared/AlertModal';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import Loader from 'components/UI/Loader';
import SearchableModal from 'components/Shared/SearchableModal';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { useAppDispatch, useAppSelector } from 'store';
import { showResponse } from 'store/actions/response';
import { Checkbox } from 'components/shadcn/checkbox';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { parseBoolean } from 'config/setConfig';
import { updateProfileBasic } from 'api/users';
import { IonIcon } from 'components/UI/IonIcon';
const defaultAvatar = require('../../assets/default_avatar.png');

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 10,
};

const center = {
  lat: 51.1657,
  lng: 10.4515,
};

export const MapScreen = () => {
  const { profileDetails, drawer, btn, searchModal } = useLanguage();
  const { user } = useAppSelector((state) => state.users);
  const { isGuest, showOnMap } = user;
  const hideOnMap = !showOnMap;
  const { dpd_link, map_ask_user_to_hide } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC8jLmT4_QQYf2CK4WDapErtmG4wlyQJK0',
  });

  const [map, setMap] = useState(null);
  const [canShowModal, setCanShowModal] = useState<null | boolean>(null);
  const [hideUserOnMap, setHideUserOnMap] = useState(hideOnMap || false);
  const [infoModal, setInfoModal] = useState({ isInfoOpen: false, selectedMarkerId: null });
  const onClick = (isInfoOpen: boolean, selectedMarkerId) => {
    setInfoModal({
      isInfoOpen,
      selectedMarkerId,
    });
  };

  useEffect(() => {
    if (!user._id) return;
    setHideUserOnMap(hideOnMap);
    const v = getLocalStorage('showMapAlert');
    const a = parseBoolean(v, true);
    setCanShowModal(a);
    setShowModal(a);
  }, [user._id]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
    setTimeout(() => {
      if (mapRef.current) mapRef.current.state.map.setZoom(6);
    }, 300);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [state, setState] = useState({ showUsers: true, showCorps: false, loading: false, showFilters: false });
  const [showSelectedCorps, setShowSelectedCorps] = useState(false);
  const [searchableCorps, setSearchableCorps] = useState('');
  const [markers, setMarkers] = useState([]);
  const [allPoi, setAllPoi] = useState({ users: [], corps: [] });
  const [showModal, setShowModal] = useState(false);
  const styles = useStyles({});
  const [corps, setCorps] = useState({
    toShow: [] as string[],
    data: [] as string[],
    showModal: false,
  });
  useEffect(() => {
    setFilters();
  }, [state, allPoi, corps.toShow]);

  useEffect(() => {
    getMarkers();
  }, []);

  const setFilters = () => {
    const { showCorps, showUsers } = state;
    const isAlleSelected = corps.toShow.length === corps.data.length;
    const isNothingSelected = !corps.toShow.length;
    let filteredUsers = [];
    if (isAlleSelected) filteredUsers = allPoi?.users;
    if (!isAlleSelected && !isNothingSelected)
      filteredUsers = allPoi?.users.filter((item) => item.corp.filter((el) => corps.toShow.includes(el)).length);

    if (showCorps && showUsers) {
      setMarkers([...filteredUsers, ...allPoi?.corps]);
    }
    if (!showCorps && showUsers) {
      setMarkers([...filteredUsers]);
    }
    if (showCorps && !showUsers) {
      setMarkers([...allPoi?.corps]);
    }
    if (!showCorps && !showUsers) {
      setMarkers([]);
    }
  };

  const getMarkers = async () => {
    setState({ ...state, loading: true });
    const res: any = await mapGetMarkers();
    res.users.forEach((el) => {
      const plusOrMinus1 = Math.random() < 0.5 ? -1 : 1;
      const plusOrMinus2 = Math.random() < 0.5 ? -1 : 1;
      const num1 = (Math.random() / 10000) * plusOrMinus1;
      const num2 = (Math.random() / 10000) * plusOrMinus2;

      el.geolocation = {
        latitude: el.geolocation.latitude + num1,
        longitude: el.geolocation.longitude + num2,
      };
    });
    res.corps.forEach((el) => {
      const plusOrMinus1 = Math.random() < 0.5 ? -1 : 1;
      const plusOrMinus2 = Math.random() < 0.5 ? -1 : 1;
      const num1 = (Math.random() / 10000) * plusOrMinus1;
      const num2 = (Math.random() / 10000) * plusOrMinus2;

      el.geolocation = {
        latitude: el.geolocation.latitude + num1,
        longitude: el.geolocation.longitude + num2,
      };
    });
    const corpsNames = res.corps.map((el: any) => el.corpsname);
    setCorps({
      ...corps,
      toShow: corpsNames,
      data: corpsNames,
    });
    setAllPoi(res);
    setState({ ...state, loading: false });
  };

  const deleteItemHandler = (value: string) => {
    if (value === 'Alle') {
      setCorps({ ...corps, toShow: [] });
      return;
    }
    setCorps({ ...corps, toShow: corps.toShow.filter((el) => el !== value) });
  };

  const selectItemHandler = (value: string) => {
    if (value === 'Alle') {
      setCorps({ ...corps, toShow: corps.data });
      return;
    }
    setCorps({ ...corps, toShow: [...corps.toShow, value] });
  };
  const ifUserGuestHandler = isGuest
    ? () => {
        dispatch(showResponse({ message: 'Das Ansehen von Nutzerprofilen ist nur für Mitglieder möglich.' }));
        return;
      }
    : false;

  const mapRef = useRef<GoogleMap>(null);
  return (
    <>
      <Layout>
        <ButtonBase
          style={{ position: 'absolute', top: -40, right: 0, width: 28, height: 28, borderRadius: 28, zIndex: 99 }}
          onClick={() => setShowModal(true)}
        >
          <InfoOutlined style={{ color: theme.ACTIVE_INPUT, width: 28, height: 28 }} />
        </ButtonBase>
        <ScreenTitle
          title={drawer.map}
          rightBlock={
            <button onClick={() => setShowModal(true)} className="mt-2">
              <IonIcon name="information-circle-outline" color={theme.ACTIVE_INPUT} size={32} />
            </button>
          }
        />
        <div className={styles.container}>
          <div
            style={{
              height: 40,
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: 10,
              backgroundColor: theme.BACKGROUND,
              borderRadius: 10,
              marginBottom: 10,
              display: 'flex',
            }}
          >
            <div style={{ flexDirection: 'row', width: '70%', justifyContent: 'flex-start', display: 'flex' }}>
              {/* @ts-ignore */}
              <CheckboxUI
                isChecked={state.showUsers}
                setIsChecked={() => setState({ ...state, showUsers: !state.showUsers })}
                label={profileDetails.user}
                wrapperStyle={{ width: 100 }}
              />
              <ButtonBase
                style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, display: 'flex' }}
                onClick={() => setCorps({ ...corps, showModal: true })}
              >
                <FilterListOutlined style={{ color: theme.ACTIVE_INPUT, width: 18, height: 18 }} />
                <span className={styles.label}>{btn.select}</span>
              </ButtonBase>
            </div>
            {/* @ts-ignore */}
            <CheckboxUI
              isChecked={state.showCorps}
              setIsChecked={() => setState({ ...state, showCorps: !state.showCorps })}
              label={profileDetails.clubLabelPlural}
              wrapperStyle={{ width: '30%' }}
            />
          </div>
          {corps.toShow?.length !== allPoi.corps?.length && !!corps.toShow?.length && (
            <div
              style={{
                width: '100%',
                backgroundColor: theme.BACKGROUND,
                borderRadius: 10,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ButtonBase
                onClick={() => setShowSelectedCorps(!showSelectedCorps)}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: 7,
                  borderRadius: 10,
                  marginBottom: 7,
                }}
              >
                <span className={styles.label}>Filter anzeigen</span>
                <ChevronLeft style={{ rotate: showSelectedCorps ? '90deg' : '-90deg', color: theme.ACTIVE_INPUT }} />
              </ButtonBase>
              {showSelectedCorps && (
                <div
                  style={{ padding: 15, maxHeight: 400, overflowY: 'auto', minHeight: 40, alignItems: 'flex-start' }}
                >
                  <Grid container spacing={3}>
                    {corps.toShow.map((el) => (
                      <MultipleModalItem item={el} deleteItemHandler={deleteItemHandler} name="corps" key={el} />
                    ))}
                  </Grid>
                </div>
              )}
            </div>
          )}
          {isLoaded ? (
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={containerStyle}
              center={center}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <MarkerClusterer
                options={{
                  gridSize: 100,
                  batchSize: 100,
                  maxZoom: 20,
                }}
              >
                {(clusterer) => (
                  <>
                    {markers.map((marker: any) => {
                      if (!marker?.geolocation?.latitude || !marker?.geolocation?.latitude) return <></>;
                      const type = marker.fullName?.length ? 'user' : 'corp';
                      const filter = type === 'user' ? marker._id : marker.corpsname;
                      const markerLabel = isGuest ? 'App nutzer' : marker.fullName || marker.corpsname;
                      return (
                        <MapMarker
                          key={marker._id || marker.corpsname}
                          //@ts-ignore
                          type={type}
                          clusterer={clusterer}
                          markerData={{
                            lat: marker.geolocation.latitude,
                            lng: marker.geolocation.longitude,
                            fullName: markerLabel,
                            id: marker._id || marker.corpsname,
                            filter,
                            type,
                          }}
                          image={marker.photo || defaultAvatar}
                          isSelected={filter === infoModal.selectedMarkerId}
                          isInfoOpen={filter === infoModal.selectedMarkerId && infoModal.isInfoOpen}
                          userIsGuest={ifUserGuestHandler}
                          onClick={onClick}
                        />
                      );
                    })}
                  </>
                )}
              </MarkerClusterer>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </Layout>
      <AlertModal
        showModal={showModal}
        message={
          <span>
            Diese Karte wurde DSGVO-konform implementiert. Nähere Hinweise dazu befinden sich dazu in unserer{' '}
            <a href={dpd_link} target="_blank" rel={'noreferrer'} style={{ color: '#2671D1' }}>
              Datenschutzerklärung
            </a>
            .<br />
            <br />
            Die Pins auf der Karte werden <span className="font-bold">nur ungenau dargestellt</span>, auf Basis der
            PLZ/Stadt aber ohne Straße/Nr. Damit ist <span className="font-bold">kein Rückschluss</span> auf{' '}
            <span className="font-bold">konkrete Adressen</span> der Nutzer möglich.
          </span>
        }
      >
        <div style={{ flexDirection: 'column', alignItems: 'center' }}>
          <div className="flex flex-row items-center gap-2 mb-4 text-sm">
            <Checkbox
              checked={!canShowModal}
              onCheckedChange={() => {
                localStorage.setItem('showMapAlert', (!canShowModal).toString());
                setCanShowModal(!canShowModal);
              }}
            />
            <span>Nicht erneut anzeigen</span>
          </div>
          {map_ask_user_to_hide && (
            <div className="flex flex-row items-center gap-2 mb-4 text-sm">
              <Checkbox
                checked={hideUserOnMap}
                onCheckedChange={async () => {
                  const res = await updateProfileBasic({ showOnMap: hideUserOnMap });
                  setHideUserOnMap(!res?.showOnMap);
                  await getMarkers();
                }}
              />
              <span>Mich nicht auf der Karte anzeigen (auch nicht nur ungefähr)</span>
            </div>
          )}
          <div className="self-center items-center">
            <Button
              label="Verstanden"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
        </div>
      </AlertModal>
      <SearchableModal
        onClick={() => setCorps({ ...corps, showModal: false })}
        modalTitle={searchModal.pleaseSelect}
        setValueHandler={() => setCorps({ ...corps, showModal: false })}
        modalOpen={corps.showModal}
        value={searchableCorps}
      >
        <MultipleSearchableInput
          data={['Alle', ...corps.data]}
          values={corps.toShow}
          value={searchableCorps}
          onChange={(e) => setSearchableCorps(e.target.value)}
          onSelect={(value) => selectItemHandler(value)}
          deleteItemHandler={(value) => deleteItemHandler(value)}
          currentName=""
        />
      </SearchableModal>
      <Loader showLoader={state.loading} />
    </>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    // flex: 1,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '92vh',
    backgroundColor: theme.BACKGROUND_SECONDARY,
  },
  label: {
    fontSize: 14,
    letterSpacing: 0.5,
    fontFamily: 'Roboto',
    color: theme.TEXT_SECONDARY,
    marginLeft: 5,
  },
});
