// @ts-nocheck
import * as React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { getConfig } from 'config/config';
import { useAppSelector } from 'store';
import { useLanguage } from 'languages/languageContext';
import { Checkbox } from 'components/shadcn/checkbox';

const { theme, config } = getConfig();

const styles = createStyles({
  text: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    padding: '0 1px',
    lineHeight: 1.6,
    color: theme.TEXT_SECONDARY,
    textAlign: 'center',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    paddingHorizontal: 20,
  },
  link: {
    color: '#2671D1',
  },
});

interface Props extends WithStyles<typeof styles> {
  language?: any;
  type?: 'signUp' | 'otherInfo' | 'resetPassword' | 'info&email';
}

const Conditions: React.FC<Props> = ({ classes, type }) => {
  const { signUpDefault, signIn } = useLanguage();
  const { dpd_link, tou_link, registration_agb_checkbox, impressum } = useAppSelector((state) => state.common);

  const descr0 = registration_agb_checkbox && type === 'signUp' ? signUpDefault.descr01 : signUpDefault.descr0;

  const SignUp = () => (
    <>
      {descr0}{' '}
      <a className={classes.link} href={tou_link} target="_blank" rel="noreferrer">
        AGB
      </a>{' '}
      {signUpDefault.descr1}{' '}
      <a className={classes.link} href={dpd_link} target="_blank" rel="noreferrer">
        DSE
      </a>{' '}
      {signUpDefault.descr2}{' '}
    </>
  );

  const Info = () => (
    <>
      {signIn.description0}{' '}
      <a className={classes.link} href="mailto:support@connact.app">
        support@connact.app
      </a>{' '}
      {signIn.description1}{' '}
      <a className={classes.link} href="tel:+491704980594">
        +49 170 49 805 94
      </a>{' '}
      {signIn.description2} |{' '}
      <a className={classes.link} target="_blank" href={impressum} rel="noreferrer">
        Impressum
      </a>
      .
    </>
  );

  return (
    <div className="w-full flex flex-row">
      <div className={classes.textWrapper}>
        <div className={`${classes.text}`}>
          {type === 'resetPassword' &&
            (registration_agb_checkbox ? (
              <>
                Mit erstmaliger Aktivierung meines Accounts über die “Passwort vergessen” Funktionen akzeptiere ich
                hiermit die{' '}
                <a className={classes.link} href={tou_link} target="_blank" rel="noreferrer">
                  Nutzungsbedingungen{' '}
                </a>{' '}
                /{' '}
                <a className={classes.link} href={dpd_link} target="_blank" rel="noreferrer">
                  DSE
                </a>
                .
              </>
            ) : (
              <>
                Mit erstmaliger Aktivierung deines Accounts über die “Passwort vergessen” Funktionen akzeptierst du die{' '}
                <a className={classes.link} href={tou_link} target="_blank" rel="noreferrer">
                  AGB
                </a>{' '}
                /{' '}
                <a className={classes.link} href={dpd_link} target="_blank" rel="noreferrer">
                  DSE
                </a>
                .
              </>
            ))}
          {type === 'signUp' && <SignUp />}
          {type === 'otherInfo' && (
            <>
              {!registration_agb_checkbox && <SignUp />}
              <Info />
            </>
          )}

          {type === 'info&email' && (
            <>
              <SignUp />
              <Info />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Conditions);
